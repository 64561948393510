import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login/index')
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('../views/setting/index')
  },
  {
    path: '/sms',
    name: 'sms_index',
    component: () => import('../views/sms/index')
  },
  {
    path: '/sms_detail',
    name: 'sms_detail',
    component: () => import('../views/sms/detail')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
